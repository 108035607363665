<template>
  <v-card tile width="50%" style="margin: 15px auto;">
    <v-form ref="form">
      <v-tabs
        background-color="primary"
        dark centered icons-and-text
        >
        <v-tab>
          Профиль
          <v-icon>mdi-account</v-icon>
        </v-tab>
        <v-tab>
          Контакты
          <v-icon>mdi-phone</v-icon>
        </v-tab>
        <v-tab-item eager>
          <v-card flat tile>
            <v-card-text class="pt-0">
              <v-row>
                <v-col cols="6">
                  <v-row>
                    <v-col cols="12">
                      <v-text-field
                        label="Фамилия"
                        v-model="profile.lastname"
                        required
                      />
                    </v-col>
                    <v-col cols="12">
                        <v-text-field
                        label="Имя"
                        v-model="profile.firstname"
                        required
                      />
                    </v-col>
                    <v-col cols="12">
                      <v-text-field
                        label="Отчество"
                        v-model="profile.middlename"
                        required
                      />
                    </v-col>
                  </v-row>
                </v-col>
                <v-col cols="6">
                  <AvatarField v-model="profile.image"/>
                </v-col>
                <v-col cols="4">
                  <v-menu
                    v-model="birthMenu"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    min-width="290px"
                    >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        :value="formattedBirthday"
                        label="Дата рождения"
                        append-icon="mdi-calendar"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                        required
                      />
                    </template>
                    <v-date-picker v-model="profile.birthdayday" @input="birthMenu = false"/>
                  </v-menu>
                </v-col>
                <v-col cols="4">
                  <v-text-field
                    label="Персональный ключ"
                    v-model="profile.rfidKey"
                    append-icon="mdi-key-chain"
                  />
                </v-col>
                <v-col cols="4">
                  <v-text-field
                    label="Клеймо сварщика"
                    v-model="profile.welderKey"
                  />
                </v-col>

                <v-col cols="12">
                  <v-textarea
                    name="about"
                    label="Коротко о себе"
                    rows="2"
                    v-model="profile.about"
                  />
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-tab-item>
        <v-tab-item eager>
          <v-card flat tile>
            <v-card-text>
              <v-row>
                <v-col cols="2">
                  <v-text-field
                    label="Индекс"
                    v-model="profile.zip"
                  />
                </v-col>
                <v-col cols="3">
                  <v-text-field
                    label="Страна"
                    v-model="profile.country"
                  />
                </v-col>
                <v-col cols="4">
                  <v-text-field
                    label="Регион"
                    v-model="profile.region"
                  />
                </v-col>
                <v-col cols="3">
                  <v-text-field
                    label="Обл."
                    v-model="profile.state"
                  />
                </v-col>
                <v-col cols="3">
                  <v-text-field
                    label="Город"
                    v-model="profile.city"
                  />
                </v-col>
                <v-col cols="7">
                  <v-text-field
                    label="Улица"
                    v-model="profile.street"
                  />
                </v-col>
                <v-col cols="2">
                  <v-text-field
                    label="Дом"
                    v-model="profile.building"
                  />
                </v-col>
                <v-col cols="3">
                  <v-text-field
                    label="Строенние"
                    v-model="profile.housing"
                  />
                </v-col>
                <v-col cols="3">
                  <v-text-field
                    label="Оффис"
                    v-model="profile.office"
                  />
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="6">
                  <v-text-field
                    label="Рабочий телефон"
                    v-model="profile.workPhone"
                  />
                </v-col>
                <v-col cols="6">
                  <v-text-field
                    label="Мобильный телефон"
                    v-model="profile.mobile"
                  />
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-tab-item>
      </v-tabs>
    </v-form>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn
        color="success"
        @click="save"
        >
        Сохранить
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import AvatarField from '@/components/AvatarField.vue'

export default {
  components: { AvatarField },
  data: () => ({
    profile: {},
    birthMenu: false,
  }),
  computed: {
    formattedBirthday() {
      if (!this.profile.birthday) {
        return
      }
      const [year, month, day] = this.profile.birthday.split('-')
      return `${day}-${month}-${year}`
    }
  },
  methods: {
    save() {
      this.$axios.post('/user/profile/update', this.profile).then(r => {
        this.$store.dispatch('user/setProfile', r.data.profile)
        this.$store.dispatch('setAlert', {
          message: 'Профиль успешно сохранен',
          type: 'info'
        })
      })
    }
  },
  mounted() {
    this.$axios.get('/user/profile/view').then(r => { this.profile = r.data })
  }
}
</script>
